const firebaseConfig = {
  projectId: "the-nova-d252d",
  appId: "1:975104085221:web:ce6e3c7205b914e620980c",
  databaseURL:
    "https://the-nova-d252d-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-nova-d252d.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyCPwVnKNR7hQgPTD5gDyv1_eZhpoP-fWik",
  authDomain: "the-nova-d252d.firebaseapp.com",
  messagingSenderId: "975104085221",
  measurementId: "G-Y0VCRKBLWF",
};

export default firebaseConfig;
